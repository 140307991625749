<template>
  <div :class="$style.root">
    <VDataTable
        fixed-header
        height="calc(100vh - 263px)"
        hideDefaultHeader
        :headers="headers"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="{text, colspan } in headers" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
          </tr>
          <tr :class="$style.filter" ref="filter" v-if="isReviewer || isSpectator">
            <th v-for="key in ['formId', 'inn', 'companyName', 'company']" :key="key">
              <VTextField
                  dense
                  clearable
                  hide-details
                  :value="$route.query[key]"
                  @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
              />
            </th>
            <th colspan="6" />
            <th v-for="key in Object.keys(markSections)" :key="key">
              <VSelect
                  dense
                  clearable
                  hide-details
                  :items="statuses"
                  :value="$route.query['risk_' + key]"
                  @input="value => $router.push({ query: { ...$route.query, ['risk_' + key]: value } })"
              />
            </th>
            <th v-for="key in ['statusDate', 'statusEndDate']" :key="key">
              <VTextField
                  dense
                  clearable
                  hide-details
                  :value="$route.query[key]"
                  @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
              />
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body.prepend>

      </template>
      <template v-slot:item="{ headers, item }">
        <tr :class="rowClassControl(item)">
          <template v-for="({ value }) in headers">
            <td :key="value">
              <div :class="['text-center', 'mt-2', $style.timeline]" v-if="~value.indexOf('timeline')">
                <template v-if="getItem(item, value + '.code') === STATUSES.NO">
                  <VChip color="error">
                    <VIcon left>mdi-close</VIcon>
                    Отклонено
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.YES">
                  <VChip color="success">
                    <VIcon left>mdi-check</VIcon>
                    Согласовано
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.REWORK">
                  <VChip color="orange" text-color="white">
                    <VIcon left>mdi-pencil-outline</VIcon>
                    На доработке
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.WAIT_IN_LINE">
                  <VChip color="grey" text-color="white">
                    <VIcon left>mdi-clock-outline</VIcon>
                    Ожидает очереди
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.WAIT_FOR_DECISION">
                  <VChip color="orange" text-color="white">
                    <VIcon left>mdi-clock-outline</VIcon>
                    На проверке
                  </VChip>
                </template>
                <template v-if="!getItem(item, value + '.code')">
                  <VChip color="error" text-color="white">
                    <VIcon left>mdi-eye-off-outline</VIcon>
                    Не участвует
                  </VChip>
                </template>
                <div :class="datesColorControl(getItem(item, value))">
                  <small>{{ getItem(item, value + '.dateCreate') }} <span v-if="getItem(item, value + '.dateExec')"> - {{ getItem(item, value + '.dateExec') }}</span></small>
                </div>
              </div>
              <template v-else-if="~value.indexOf('risksStatuses')">
                <div class="py-2" v-html="getItem(item, value)"></div>
              </template>
              <template v-else-if="value === 'formId'">
                <router-link :to="{name: 'pkomain/CounterpartyDetailView', params: { counterparty: getItem(item, value) }}">{{ getItem(item, value) }}</router-link>
              </template>
              <template v-else-if="value === 'company'">
                <div v-html="getItem(item, value)" />
              </template>
              <template v-else-if="value === 'isInTerritory'">
                {{ getInTerritoryValue(getItem(item, value)) }}
              </template>
              <template v-else>
                {{ ~value.indexOf('sections') ? getItem(item, value + '.name') : getItem(item, value)}}
              </template>
            </td>
          </template>
        </tr>
      </template>
      <template v-slot:item.status.name="{ item: { status } }">
        {{ status.name }} <br />
        {{status.start}} - {{ status.end}}
      </template>
    </VDataTable>
    <VProgressLinear v-if="loading" absolute indeterminate color="primary darken-2" :class="$style.progress" />
  </div>
</template>

<script>
import { forEach, get, map } from 'lodash-es';
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkomain/enums';
export default {
  name: 'RegistryList',
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
    risks: { type: Array },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
      STATUSES,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    headers() {
      return [
        { text: 'ID заявки', value: 'formId', sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Организация', value: 'name', sortable: false },
        { text: 'Предприятие', value: 'company', sortable: false },
        { text: 'Дата первой отправки на согласование', value: 'dateSend', sortable: false },
        { text: 'УЗУ', value: 'timelinePki', sortable: false, align: 'center' },
        { text: 'СБ', value: 'timelineSb', sortable: false, align: 'center' },
        { text: 'ОТиПБ', value: 'timelineOtipb', sortable: false, align: 'center' },
        { text: 'БДД', value: 'timelineBdd', sortable: false, align: 'center' },
        { text: 'На/вне территории', value: 'isInTerritory', sortable: false },
        ...Object.keys(this.markSections).map((code) => ({ text: this.markSections[code], value: `sections.${code}.value`, sortable: false})),
        { text: 'Сроки ПКО (начало)', value: 'statusDate', sortable: false },
        { text: 'Сроки ПКО (окончание)', value: 'statusEndDate', sortable: false },
      ]
    },
    statuses() {
      return [
        {text: 'Не пройдено', value: STATUSES.NOT_PASSED },
        {text: 'Пройдено', value: STATUSES.PASSED },
        {text: 'В стадии прохождения', value: STATUSES.ON_APPLICATION_APPROVING },
      ]
    },
    markSections() {
      const result = {};
      forEach(this.items, ({ sections }) => {
        forEach(sections, ({ name }, code) => {
          result[code] = name;
        });
      })
      return result;
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
    risksList() {
      return map(this.risks, (item) => ({
        text: get(item, 'name'),
        value: get(item, 'code'),
      }))
    }
  },
  methods: {
    onResize() {
      if (this.$refs.filter)
        this.$refs.filter.style.top = this.$refs.filter.previousElementSibling.offsetHeight + 'px';
    },
    getItem(item, path) {
      return get(item, path);
    },
    rowClassControl({ status }) {
      if(STATUSES.NOT_PASSED === status.code) return 'red lighten-4';
      if(STATUSES.PASSED === status.code) return 'green lighten-4';
      if(STATUSES.PASSED_WITH_APPLICATION_APPROVING === status.code) return 'green lighten-4';
      if(STATUSES.NOT_PASSED_WITH_APPLICATION_APPROVING === status.code) return 'orange lighten-4';
      return '';
    },
    datesColorControl({ code, dateCreate }) {
      //Если с даты создания прошло больше 7 дней, то подсвечиваем красным
      if (code !== STATUSES.WAIT_FOR_DECISION) return '';
      if (!dateCreate) return '';

      const date = Date.parse(dateCreate.split('.').reverse().join('-'));
      const now = new Date();
      const diff = now - date;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (days > 7) return 'red--text';
    },
    getInTerritoryValue(val) {
      if (val === null) return '';
      return val ? 'на' : 'вне';
    },
  },
  watch: {
    markSections: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.onResize();
        })
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .filter {
    th {
      top: inherit !important;
    }
  }
  th {
    width: 220px !important;
    &:nth-child(1) {
      width: 120px !important;
    }
  }
  td {
    white-space: nowrap !important;
    min-width: 100px;
  }
}
.timeline {
  min-height: 60px;
}
.progress {
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}
</style>
